import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbPopoverDirective } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { Plan, MenuTreeNode, PlanEntry } from '../../../@core/interfaces/business/plan';
import { PlanManagementComponent } from '../../../pages/plan-management/plan-management.component';
import {
  PLANNING_EXPLORER_PAGE,
  DEMAND_PLANNING_PAGE,
  SUPPLY_EXPLORER_PAGE,
  EVENT_MANAGEMENT_PAGE,
  BUSINESS_EXPLORER_PAGE,
} from '../planning-segment/widget-management.component';
import { Notification } from '@/app/@core/interfaces/business/notification';

const PAGE_TILES = {
  [BUSINESS_EXPLORER_PAGE]: 'Business Explorer',
  [PLANNING_EXPLORER_PAGE]: 'Planning Explorer',
  [DEMAND_PLANNING_PAGE]: 'Demand Planning',
  [SUPPLY_EXPLORER_PAGE]: 'Supply Explorer',
  [EVENT_MANAGEMENT_PAGE]: 'Event Management',
};

@Component({
  selector: 'cel-planning-menu',
  templateUrl: './planning-menu.component.html',
  styleUrls: ['./planning-menu.component.scss']
})
export class PlanningMenuComponent implements OnInit {

  /** Selected plan di in the dropdown. */
  @Input() selected?: Plan;

  @Input() plans: MenuTreeNode<PlanEntry>[] = []
  /** List of notifications */
  @Input() notifications: readonly Notification[] = [];

  /** Emits whenever the user selects a plan. */
  @Output() selectedChange = new EventEmitter<Plan>();

  @ViewChild(NbPopoverDirective) popover?: NbPopoverDirective;

  constructor(
    private readonly dialogService: NbDialogService,
    private readonly router: Router,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {
  }

  getPageTitle(): string {
    const title = PAGE_TILES[this.router.url as keyof typeof PAGE_TILES];
    return title || '';
  }

  openNewPlanWindow() {
    this.dialogService.open(PlanManagementComponent, {
      hasBackdrop: true,
      closeOnEsc: true,
      closeOnBackdropClick: false,
    });
  }

  selectedPlan(plan: Plan) {
    if (plan) {
      this.selectedChange.emit(plan);
      this.popover?.hide()
    }
  }

  // onDeletePlan(plan: Plan) {
  //   if (plan) {
  //     this.deletePlan.emit(plan);
  //     this.popover?.hide()
  //   }
  // }
}
