import { createReducer, on } from '@ngrx/store';

import { Actual } from '@/app/@core/interfaces/business/actual';
import { LOAD_ACTUALS, LOAD_ACTUALS_FAILED, LOAD_ACTUALS_SUCCESS } from './actua.actions';

export const ACTUAL_STATE_KEY = 'actual';

/*************************************
 * State
 *************************************/

export interface IActualState {
  data: ReadonlyArray<Actual>;
  loading: boolean;
  errors: string[];
}
export const initialState: IActualState = {
  data: [],
  loading: false,
  errors: [],
};

/*************************************
 * Reducers
 *************************************/

export const actualReducer = createReducer(
  initialState,
  on(
    LOAD_ACTUALS,
    (state: IActualState): IActualState => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    LOAD_ACTUALS_SUCCESS,
    (state, { data }): IActualState => ({
      ...state,
      loading: false,
      data,
    }),
  ),
  on(
    LOAD_ACTUALS_FAILED,
    (state, { error }): IActualState => ({ ...state, loading: false, data: [], errors: error }),
  )
);
