/**
 * Defines all actual info.
 */
export declare interface Actual {
  id: string;
  workspaceId: string;
  actualStartDate: Date;
  actualEndDate: Date;
  actualInputDatabase: string;
  actualOutputDatabase: string;
}

export function sortActualsByStartDate(actuals: Actual[]): Actual[] {
  return (actuals || []).sort((a, b) => {
    return new Date(a.actualStartDate).getTime() - new Date(b.actualStartDate).getTime()
  });
}

export function sortActualsByEndDate(actuals: Actual[]): Actual[] {
 return (actuals || []).sort((a, b) => {
    return new Date(b.actualEndDate).getTime() - new Date(a.actualEndDate).getTime()
  });
}
