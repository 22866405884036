<div class="title">{{ getPageTitle() }}</div>
<div class="plan-import-div">
  <nb-icon class="plan-item-alert" 
    icon="info-outline"
    status="primary"
    nbTooltipPlacement="left"
    nbTooltipStatus="control"
    nbTooltip="New Base Demand / Forecasts are available, please consider updating you latest plan."></nb-icon>
  <nb-form-field class="popover-container">
    <input type="text" class="filter-box" nbInput [nbPopover]="plan" nbPopoverPlacement="bottom" fieldSize="small"
      (keydown)="$event.preventDefault()" [value]="selected?.name" />
    <nb-icon nbSuffix icon="chevron-down-outline"></nb-icon>
  </nb-form-field>
  <ng-template #plan>
    <div class="popup">
      <table [nbTreeGrid]="plans">
  
        <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: ['value']"></tr>
        
        <ng-container nbTreeGridColumnDef="value">
          <td nbTreeGridCell *nbTreeGridCellDef="let row">
            <a href="javascript:void(0)" (click)="selectedPlan(row.data.plan)"
              [style.paddingLeft]="'' + (row.level + 1) * 7 + 'px'">
              {{row.data.name}}
              <nb-icon nbSuffix icon="chevron-right-outline" *ngIf="!row.expanded && row.children?.length > 0"
                class="float-right">
              </nb-icon>
              <nb-icon nbSuffix icon="chevron-down-outline" *ngIf="row.expanded && row.children?.length > 0"
                class="float-right">
              </nb-icon>
            </a>
          </td>
        </ng-container>
  
      </table>
    </div>
  </ng-template>
  <button class="new-plan-btn" (click)="openNewPlanWindow()">
    <nb-icon icon="plus-circle-outline" style="width: 16px;"></nb-icon> &nbsp; Plan
  </button>
</div>
<div class="down-share-div">
  <button class="down-share-btn">
    <nb-icon nbPrefix icon="share-outline" pack="eva"></nb-icon> Share
  </button>
  <cel-notification-box [items]="notifications"></cel-notification-box>
</div>

