import { createFeatureSelector, createSelector } from '@ngrx/store';
import { select_selectedDateRange } from '../pages/layout/layout.selectors';

import { WorkspaceState, WORKSPACE_STATE_KEY } from './workspace.state';

const selectFeature = createFeatureSelector<WorkspaceState>(WORKSPACE_STATE_KEY);
export const select_workspacesLoading = createSelector(selectFeature, (state) => state.loading);
export const select_selectedWorkspaceId = createSelector(selectFeature, (state) => state.selected);
export const select_selectedWorkspace = createSelector(
  selectFeature,
  (state) => state.data.find((d) => d.id === state.selected) || state.data[0],
);
export const select_params_loadForecastSettingsChartData = createSelector(
  select_selectedDateRange,
  (
    selectedDateRange,
  ): any | undefined => {
    return {
      planId: "632d685ce98b7d9cde408f20", // actual planId
      dateRange: selectedDateRange?.start && {
        start: selectedDateRange?.start,
        end: selectedDateRange?.end
      },
      workspaceId: undefined, // actual plan's workspaceId
      forecastCollection: "CustomerDemand", // actual plan's forecastCollection
    };
  },
);

