import { Injectable } from '@angular/core';
import { Workspace } from '../interfaces/common/workspace';

@Injectable()
export class WorkspaceService {
  checkIsDisplayMonthYearOnly(workspace: Workspace): boolean {
    if (workspace != undefined && workspace.hasOwnProperty("settings")) {
      return workspace.settings?.displayMonthYearOnly ?? false;
    } else {
      return false;
    }
  }

  resetRange(range: Date[]) {
    range[0] = new Date(range[0].getFullYear(), range[0].getMonth(), 1);
    range[1] = new Date(range[1].getFullYear(), range[1].getMonth() + 1, 0);
  }
}
