<div class="notification-toggle">
  <nb-badge [text]="text" status="danger" position="top right" *ngIf="text"></nb-badge>
  <button
    nbButton
    ghost
    size="small"
    shape="round"
    [nbPopover]="template"
    nbPopoverPlacement="bottom"
    (click)="handleToggle()"
  >
    <nb-icon icon="bell"></nb-icon>
  </button>
</div>

<ng-template #template let-data>
  <div *ngIf="!items || items.length === 0" class="notification-list-placeholder">You have no message</div>
  <nb-list *ngIf="items && items.length > 0">
    <nb-list-item class="notification-item" *ngFor="let item of items">
      <label [class]="item.checked ? '' : 'highlighted'">{{item.message}}</label>
    </nb-list-item>
  </nb-list>
</ng-template>
