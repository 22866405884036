import { createReducer, on } from '@ngrx/store';

import { GET_CURRENT_USER_SUCCESS } from '../auth/auth.actions';
import { Workspace } from '@/app/@core/interfaces/common/workspace';
import { BEGIN_UPDATE_WORKSPACE_SETTINGS, SELECT_WORKSPACE, UPDATE_WORKSPACE_SETTINGS_FAILED, UPDATE_WORKSPACE_SETTINGS_SUCCESS } from './workspace.actions';

export const WORKSPACE_STATE_KEY = 'workspace';

/*************************************
 * State
 *************************************/

export interface WorkspaceState {
  data: ReadonlyArray<Workspace>;
  selected: string | null;
  loading: boolean;
  errors: string[];
  updatingWorkspaceSettings: boolean;
  updatingWorkspaceSettings_error?: any;
}

export const initialState: WorkspaceState = {
  data: [],
  selected: null,
  loading: false,
  errors: [],
  updatingWorkspaceSettings: false,
};

/*************************************
 * Reducer
 *************************************/

export const workspaceReducer = createReducer(
  initialState,
  on(
    GET_CURRENT_USER_SUCCESS,
    (state, { data }): WorkspaceState => ({
      ...state,
      data: data.workspaces || [],
    }),
  ),
  on(SELECT_WORKSPACE, (state, { id }): WorkspaceState => ({ ...state, selected: id })),
  on(
    BEGIN_UPDATE_WORKSPACE_SETTINGS,
    (state): WorkspaceState => ({ 
      ...state,
      updatingWorkspaceSettings: true, 
      updatingWorkspaceSettings_error: undefined 
    }),
  ),
  on(
    UPDATE_WORKSPACE_SETTINGS_FAILED,
    (state, { error }): WorkspaceState => ({
      ...state,
      updatingWorkspaceSettings: false,
      updatingWorkspaceSettings_error: error,
    }),
  ),
  on(
    UPDATE_WORKSPACE_SETTINGS_SUCCESS,
    (state, { data }): WorkspaceState => ({ ...state, updatingWorkspaceSettings: false, data: [data] || []}),
  ),
);
