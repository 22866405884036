<nb-card style="width: 700px; height: 76te0px;">
  <nb-card-header class="card-title">FORECAST SETTINGS</nb-card-header>
  <nb-card-body>
  <div class="row">
      <p class="col-12 subtitle mb-0">Standard Forecast Horizon:</p>
      <div class="col-4">
        <nb-select class="col-4 pl-0 pr-0 forecast-select" [selected]="selectedHorizon" (selectedChange)="changeHorizon($event)">
          <nb-option-group title="Horizon">
            <nb-option *ngFor="let horizon of horizons" [value]="horizon">
              <div class="option__item">
                <input type="radio" name="segement" [value]="horizon" [checked]="this.selectedHorizon == horizon"><label for="">{{ horizon }}</label>
              </div>
            </nb-option>
          </nb-option-group>
        </nb-select>
      </div>
    </div>

    <br>

    <div class="row">
      <p class="col-12 subtitle">Forecast Aggregation:</p>

      <div class="col-4">
        <label for="" class="label forecast-label">Products Aggregation:</label>
        <nb-select multiple class="col-4 pl-0 pr-0 forecast-select" [(selected)]="selectedProductAggr" (selectedChange)="changeSelectedProductAggr($event)">
          <nb-option-group *ngFor="let group of productGroups" [title]="group.name">
            <nb-option class="forecast-option" *ngFor="let option of group.children" [value]="option.name">
              <div class="value">{{ option.label }}</div>
            </nb-option>
          </nb-option-group>
        </nb-select>
      </div>

      <div class="col-4">
        <label for=""class="label forecast-label">Customers/Location Aggregation:</label>
        <nb-select multiple class="col-4 pl-0 pr-0 forecast-select" [(selected)]="selectedCustomerAggr" (selectedChange)="changeSelectedCustomerAggr($event)">
          <nb-option-group *ngFor="let group of customerLocationGroups" [title]="group.name">
            <nb-option class="forecast-option" *ngFor="let option of group.children" [value]="option.name">
              <div class="value">{{ option.label }}</div>
            </nb-option>
          </nb-option-group>
        </nb-select>
      </div>

      <div class="col-4">
        <label for=""class="label forecast-label">Time Aggregation:</label>
        <nb-select class="col-4 pl-0 pr-0 forecast-select" [selected]="selectedtimeAggr" (selectedChange)="changeTimeAggr($event)">
          <nb-option-group title="Time">
            <nb-option *ngFor="let timeAggr of timeAggrs" [value]="timeAggr">
              <div class="option__item">
                <input type="radio" name="segement" [value]="timeAggr" [checked]="this.selectedtimeAggr == timeAggr"><label for="">{{ timeAggr }}</label>
              </div>
            </nb-option>
          </nb-option-group>
        </nb-select>
      </div>
    </div>

    <br>

    <div class="row">
      <p class="col-12 subtitle">Explore Products / Customers:</p>

      <div class="col-6" style="border-right: 1px solid #999;">
        <p class="label forecast-label">Product ID:</p>

        <div style="height: 40px;">
          <div style="float: left; width: 60%;">
            <nb-select
              class="col-4 pl-0 forecast-select"
              [(ngModel)]="selectedProductsOption"
              (selectedChange)="changeProductsOption($event)"
              >
              <nb-icon
                nbSuffix
                icon='close-circle'
                class='icon'
                [ngClass]="{'secondary': delistedProductRef.includes(selectedProductsOption)}"
              ></nb-icon>
              <nb-option-group class="title-class" title="Suspected Delisted Product ID">
                <nb-option *ngFor="let option of productsOptions" [value]="option">
                  <div class="option__item">
                    <input type="radio" name="productsOptions" [value]="option" [checked]="this.selectedProductsOption == option"><label for="">{{ option }}</label>
                    <nb-icon
                      icon="close-circle"
                      class="icon"
                      [ngClass]="{'secondary': delistedProductRef.includes(option)}"
                    ></nb-icon>
                  </div>
                </nb-option>
              </nb-option-group>
            </nb-select>
          </div>
          <a class="btn-delisted" (click)="addDelistedProductRef()">
            <span class="plus-sign">+</span>
            Add to Delisted Product ID
          </a>
        </div>
        <nz-spin nzTip="Loading..." [nzSpinning]="productChartDataLoading">
          <div
            class="chart"
            echarts
            [options]="chartOption"
            [merge]="productChartOptions"
            (chartInit)="onChartInit($event)"
            style="height: 180px;"
            class="echart"
          ></div>
        </nz-spin>

        <div>
          <p class="subtitle">Delisted Product ID:</p>
          <div class="delisted-box">
            {{ delistedProductRef.join(", ") }}
          </div>
        </div>
      </div>

      <div class="col-6" style="border-left: 1px solid #999;">
        <p class="label forecast-label">Customer Ref:</p>
        
        <div style="height: 40px;">
          <div style="float: left; width: 60%;">
            <nb-select class="col-4 pl-0 forecast-select" [selected]="selectedCustomersOption" (selectedChange)="changeCustomersOption($event)">
              <nb-option-group title="Suspected Delisted Customer Ref">
                <nb-option *ngFor="let option of customersOptions" [value]="option">
                  <div class="option__item">
                    <input type="radio" name="customersOptions" [value]="option" [checked]="this.selectedCustomersOption == option"><label for="">{{ option }}</label>
                    <nb-icon
                      icon="close-circle"
                      class="icon"
                      [ngClass]="{'secondary': delistedCustomerRef.includes(option)}"
                      ></nb-icon>
                  </div>
                </nb-option>
              </nb-option-group>
            </nb-select>
          </div>
          <a class="btn-delisted" (click)="addDelistedCustomerRef()">
            <span class="plus-sign">+</span>
            Add to Delisted Customer Ref
          </a>
        </div>

        <nz-spin nzTip="Loading..." [nzSpinning]="customerChartDataLoading">
          <div
            class="chart"
            echarts
            [options]="chartOption"
            [merge]="customerChartOptions"
            (chartInit)="onChartInit($event)"
            style="height: 180px;"
            class="echart"
          ></div>
        </nz-spin>
        
        <div>
          <p class="subtitle">Delisted Customer Ref:</p>
          <div class="delisted-box">
            {{ delistedCustomerRef.join(", ") }}
          </div>
        </div>

      </div>
    </div>

    <br>

    <div class="row">
      <div class="col-6"></div>
      <div class="col-6">
        <button class="forecast-btn -cancel" (click)="close()">Cancel</button>
        <button class="forecast-btn -save" (click)="save()">
          Save
        </button>
      </div>
    </div>
  </nb-card-body>
</nb-card>
