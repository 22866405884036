<nb-card
  [class.createPlanWithPlanImport]="!updateMode && !useForecasting"
  [class.createPlanWithForecaster]="!updateMode && useForecasting"
  [class.updatePlan]="updateMode"
>
  <nb-card-header class="plan-mgt-header">
    <div class="plan-mgt-title-div row">
      <div
        class="plan-mgt-title"
        [class.update-popup-width]="updateMode"
        [class.create-popup-width]="!updateMode"
      >
        {{ updateMode ? 'Update Plan' : 'New Plan' }}
      </div>
      <button class="plan-mgt-btn -close" (click)="close()">
        <nb-icon
          class="plan-mgt-btn -close-icon"
          icon="close-outline"
          pack="eva"
          fullWidth
        ></nb-icon>
      </button>
    </div>
  </nb-card-header>
  <nb-card-body class="col plan-mgt-content">
    <div class="plan-mgt-checkbox -div">
      <div class="plan-mgt-input-title">You want to</div>
      <nb-checkbox
        class="plan-mgt-checkbox"
        [checked]="!updateMode"
        [disabled]="!updateMode"
        (checkedChange)="changeMode(false)"
      >
        Create a new plan
      </nb-checkbox>
      <nb-checkbox
        class="plan-mgt-checkbox -update"
        [checked]="updateMode"
        [disabled]="updateMode"
        (checkedChange)="changeMode(true)"
      >
        Update existing plan
      </nb-checkbox>
    </div>

    <div *ngIf="updateMode; else createPlanTemplate">
      <ng-container *ngTemplateOutlet="updatePlanTemplate"></ng-container>
    </div>
  </nb-card-body>
</nb-card>

<ng-template #updatePlanTemplate>
  <div class="plan-mgt-checkbox -div">
    <div class="plan-mgt-input-title">Plan Type</div>
    <nb-checkbox class="plan-mgt-checkbox" [checked]="!budgetPlan" [disabled]="!budgetPlan"
      (checkedChange)="changePlanType(false)">
      Tactical Plan
    </nb-checkbox>
    <nb-checkbox class="plan-mgt-checkbox -update" [checked]="budgetPlan" [disabled]="budgetPlan"
      (checkedChange)="changePlanType(true)">
      Budget Plan
    </nb-checkbox>
  </div>

  <div class="plan-mgt-latest-plan">
    <div class="plan-mgt-input-title">Latest plan</div>
    <span class="subtitle">{{selectedLatestPlan?.name}}</span>
  </div>

  <div class="plan-mgt-checkbox -div">
    <div class="plan-mgt-input-title">By using</div>
    <nb-checkbox class="plan-mgt-checkbox" [checked]="useForecasting" [disabled]="useForecasting"
      (checkedChange)="changeCreateMode(true)">
      SIMCEL Forecaster
    </nb-checkbox>
    <nb-checkbox class="plan-mgt-checkbox -update" [checked]="!useForecasting" [disabled]="!useForecasting"
      (checkedChange)="changeCreateMode(false)">
      Imported Plan
    </nb-checkbox>
  </div>

  <div *ngIf="!useForecasting">
    <div class="plan-mgt-input-title">Select imported Plan</div>
    <nb-select class="col-2 plan-mgt-select -box" placeholder="Select a Plan Import" [(ngModel)]="selectedPlanImport">
      <nb-option class="plan-mgt-select -option" *ngFor="let import of planImports | async" [value]="import">
        {{ import.name }}
      </nb-option>
    </nb-select>
  </div>
  
  <div *ngIf="useForecasting">
    <div class="plan-mgt-input-title">Select Forecasting Method</div>
  
    <nb-select class="col-2 plan-mgt-select -box" placeholder="Select a Forecasting Method"
      [(ngModel)]="forecastingMethod" (selectedChange)="changeForecastingMethod($event)">
      <nb-option class="plan-mgt-select -option" value="PROPHET">Prophet</nb-option>
      <nb-option class="plan-mgt-select -option" value="STL">STL</nb-option>
      <nb-option class="plan-mgt-select -option" value="Decision Tree">Decision Tree</nb-option>
      <nb-option class="plan-mgt-select -option" value="AdaBoost">AdaBoost</nb-option>
      <nb-option class="plan-mgt-select -option" value="Extra Trees">Extra Trees</nb-option>
      <nb-option class="plan-mgt-select -option" value="Random Forest">Random Forest</nb-option>
      <nb-option class="plan-mgt-select -option" value="XGB">XGB</nb-option>
    </nb-select>
  </div>
  
  <div class="col plan-import-input-div">
    <div class="plan-mgt-input-title">Description</div>
    <textarea class="plan-import-input-box -description" [(ngModel)]="planDescription"
      placeholder="Text Input"></textarea>
  </div>

  <div *ngrxLet="updatingPlan as submitting">
    <p class="open-forecast-settings">
      <a href="javascript:void(0);" (click)="openForecastSettingsDialog()">Open Forecast Settings</a>
      <a href="javascript:void(0);" class="delete-btn" (click)="showWarningDeleteDialog()">Delete plan</a>
    </p>
    <div class="plan-mgt-btn -div">
      <button class="plan-mgt-btn -cancel" (click)="close()">Cancel</button>
      <button class="plan-mgt-btn -save" [disabled]="isUpdateButnDisabled() || submitting"
        style="--save-btn-color:{{ isUpdateButnDisabled() ? '#c3c3c3' : '#426c9d' }};" (click)="update()">Update</button>
    </div>
  </div>
</ng-template>

<ng-template #createPlanTemplate>
  <div class="plan-mgt-checkbox -div">
    <div class="plan-mgt-input-title">Plan Type</div>
    <nb-checkbox class="plan-mgt-checkbox" [checked]="!budgetPlan" [disabled]="!budgetPlan"
      (checkedChange)="changePlanType(false)">
      Tactical Plan
    </nb-checkbox>
    <nb-checkbox class="plan-mgt-checkbox -update" [checked]="budgetPlan" [disabled]="budgetPlan"
      (checkedChange)="changePlanType(true)">
      Budget Plan
    </nb-checkbox>
  </div>

  <div class="col plan-import-input-div">
    <div class="plan-mgt-input-title">Title</div>
    <input class="plan-import-input-box -title" type="text" [(ngModel)]="planTitle" />
  </div>

  <div class="plan-mgt-checkbox -div">
    <div class="plan-mgt-input-title">By using</div>
    <nb-checkbox
      class="plan-mgt-checkbox"
      [checked]="useForecasting"
      [disabled]="useForecasting"
      (checkedChange)="changeCreateMode(true)"
    >
      SIMCEL Forecaster
    </nb-checkbox>
    <nb-checkbox
      class="plan-mgt-checkbox -update"
      [checked]="!useForecasting"
      [disabled]="!useForecasting"
      (checkedChange)="changeCreateMode(false)"
    >
      Imported Plan
    </nb-checkbox>
  </div>

  <div *ngIf="!useForecasting">
    <div class="plan-mgt-input-title">Select Plan</div>
    <nb-select
      class="col-2 plan-mgt-select -box"
      placeholder="Select a Plan Import"
      [(ngModel)]="selectedPlanImport"
    >
      <nb-option
        class="plan-mgt-select -option"
        *ngFor="let import of planImports | async"
        [value]="import"
      >
        {{ import.name }}
      </nb-option>
    </nb-select>
  </div>

  <div *ngIf="useForecasting">
    <div class="plan-mgt-input-title">Select Forecasting Method</div>

    <nb-select
      class="col-2 plan-mgt-select -box"
      placeholder="Select a Forecasting Method"
      [(ngModel)]="forecastingMethod"
      (selectedChange)="changeForecastingMethod($event)"
    >
      <nb-option class="plan-mgt-select -option" value="PROPHET">Prophet</nb-option>
      <nb-option class="plan-mgt-select -option" value="STL">STL</nb-option>
      <nb-option class="plan-mgt-select -option" value="Decision Tree">Decision Tree</nb-option>
      <nb-option class="plan-mgt-select -option" value="AdaBoost">AdaBoost</nb-option>
      <nb-option class="plan-mgt-select -option" value="Extra Trees">Extra Trees</nb-option>
      <nb-option class="plan-mgt-select -option" value="Random Forest">Random Forest</nb-option>
      <nb-option class="plan-mgt-select -option" value="XGB">XGB</nb-option>
    </nb-select>
  </div>

  <div *ngIf="useForecasting" class="col plan-import-input-div" style="margin-bottom: 15px;">
    <div class="plan-mgt-input-title">Select Period</div>
    <nz-range-picker
      class="form-control"
      [nzMode]="dateRangeSelectionText.toLowerCase()"
      [formControl]="form_rangeSelected"
      [nzPlaceHolder]="'Select ' + dateRangeSelectionText + ' Range'"
      [nzAllowClear]="false"
      [nzRanges]="presettedDateRanges"
      (nzOnCalendarChange)="changeDate($event)"
    ></nz-range-picker>
  </div>

  <div class="col plan-import-input-div">
    <div class="plan-mgt-input-title">Description</div>
    <textarea
      class="plan-import-input-box -description"
      [(ngModel)]="planDescription"
      placeholder="Text Input"
    ></textarea>
  </div>

  <div *ngIf="createdPlan" class="row created-plan-status-summary">
    Created plan : {{ createdPlan?.name }}
    <span *ngIf="createdPlan?.tasksSummary as statusSummary">
      <nb-icon
        *ngIf="statusSummary.status === 'inProgress'"
        icon="alert-circle-outline"
        status="warning"
        nbTooltip="Plan currently processing"
        nbTooltipIcon="alert-circle-outline"
      ></nb-icon>
      <nb-icon
        *ngIf="statusSummary.status === 'failed'"
        icon="alert-circle-outline"
        status="danger"
        [nbTooltip]="statusSummary.error"
        nbTooltipIcon="alert-circle-outline"
      ></nb-icon>
    </span>
  </div>
  <div *ngrxLet="creatingPlan as submitting">
    <p class="open-forecast-settings">
      <a href="javascript:void(0);" (click)="openForecastSettingsDialog()">Open Forecast Settings</a>
    </p>
    <div class="plan-mgt-btn -div">
      <button class="plan-mgt-btn -cancel" (click)="close()">Cancel</button>
      <button
        class="plan-mgt-btn -save"
        [disabled]="isCreateButnDisabled() || submitting"
        style="--save-btn-color:{{ isCreateButnDisabled() ? '#c3c3c3' : '#426c9d' }};"
        (click)="create()"
      >
        Create
      </button>
    </div>
  </div>
</ng-template>
