import { IEditWorkspaceSettingParams } from '@/app/@core/entity/workspace.service';
import { createAction, props } from '@ngrx/store';

// NOTE: not used yet
export const SELECT_WORKSPACE = createAction(
  '[🤖 Workspace API] Select Workspace',
  props<{ id: string }>(),
);
export const BEGIN_UPDATE_WORKSPACE_SETTINGS = createAction(
  '[🌱 Workspace API] Updating Workspace Settings...',
  props<{ params: IEditWorkspaceSettingParams }>(),
);
export const UPDATE_WORKSPACE_SETTINGS_SUCCESS = createAction(
  '[⚡️ Workspace API] Update Workspace Settings successfully',
  props<{ data: any }>(),
);
export const UPDATE_WORKSPACE_SETTINGS_FAILED = createAction(
  '[⚡️ Workspace API] Update Workspace Settings failed',
  props<{ error: any }>(),
);
