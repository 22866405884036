import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbDialogRef,
  NbToastrService,
  NbGlobalLogicalPosition,
  NbDialogService,
} from '@nebular/theme';
import { FormControl } from '@angular/forms';
import { filter, map, pairwise, Subject, takeUntil, tap, withLatestFrom, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { addYears } from 'date-fns';

import { ForecastSettingsComponent } from '../explorer/planning-explorer/widgets/forecast-settings/forecast-settings.component';
import { Plan } from '../../@core/interfaces/business/plan';
import { PlanImport } from '../../@core/interfaces/business/plan-import';
import { SimpleDateRange } from '../../@core/interfaces/common/date-range';
import { convertToSimpleDateRangeFromRangepickerVal } from '../../../utils/calendar';
import { DeletePlanWarningDialog } from '@/app/@components/common/delete-plan-warning-dialog/delete-plan-warning-dialog.component';
import { select_availablePlanImports } from '@/store/plan-import/plan-import.selectors';
import { select_latestBudgetPlan, select_latestFuturePlan } from '@/store/plan/plan.selectors';
import { LOAD_PLAN_IMPORTS } from '@/store/plan-import/plan-import.actions';
import { BEGIN_CREATE_NEW_PLAN, BEGIN_UPDATE_PLAN, BEGIN_DELETE_PLAN } from '@/store/pages/layout/layout.actions';
import {
  select_creatingPlan,
  select_creatingPlanError,
  select_updatingPlan,
  select_updatingPlanError
} from '@/store/pages/layout/layout.baseSelectors';
import { select_selectedWorkspace } from '@/store/workspace/workspace.selectors';
import { Workspace } from '@/app/@core/interfaces/common/workspace';
import { WorkspaceService } from '@/app/@core/utils';

// const now = new Date();
// const startOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);

@Component({
  selector: 'cel-plan-management',
  templateUrl: './plan-management.component.html',
  styleUrls: ['./plan-management.component.scss'],
})
export class PlanManagementComponent implements OnInit, OnDestroy {
  form_rangeSelected = new FormControl([]);

  selectedPlanImport?: PlanImport;
  selectedUpdatePlanImport?: PlanImport;
  selectedLatestPlan?: Plan;
  planTitle = '';
  planDescription = '';
  planDateRange?: SimpleDateRange;
  preDate: Date = new Date();
  now: Date[] = [new Date(), new Date()];
  updateMode = false;
  selectExistingPlan = 10;
  forecastingMethod = 'PROPHET';
  useForecasting = false;
  budgetPlan = false;
  createdPlan?: Plan;

  latestFuturePlan = this.store.select(select_latestFuturePlan);
  latestBudgetPlan = this.store.select(select_latestBudgetPlan);
  planImports = this.store.select(select_availablePlanImports);
  creatingPlan = this.store.select(select_creatingPlan);
  creatingPlan_error = this.store.select(select_creatingPlanError);

  updatingPlan = this.store.select(select_updatingPlan);
  updatingPlan_error = this.store.select(select_updatingPlanError);

  presettedDateRanges = {
    'One year plan': [this.preDate, addYears(this.preDate, 1)],
    'Two year plan': [this.preDate, addYears(this.preDate, 2)],
    'Three year plan': [this.preDate, addYears(this.preDate, 3)],
    'Five year plan': [this.preDate, addYears(this.preDate, 5)],
  };
  displayMonthYearOnly: boolean = false;
  dateRangeSelectionText = "Date";

  /** Unsubscribe helper. */
  private readonly unsubscribe = new Subject<void>();

  constructor(
    private readonly dialogService: NbDialogService,
    private readonly store: Store,
    private readonly dialogRef: NbDialogRef<Plan>,
    private readonly toastrService: NbToastrService,
    private readonly workspaceService: WorkspaceService
  ) { }

  ngOnInit(): void {
    this.store.dispatch(LOAD_PLAN_IMPORTS());
    this.bindFormControlToProp();

    this.store.select(select_selectedWorkspace).subscribe((workspace: Workspace) => {
      this.displayMonthYearOnly = this.workspaceService.checkIsDisplayMonthYearOnly(workspace);
      if (this.displayMonthYearOnly) {
        this.dateRangeSelectionText = "Month";
      } else {
        this.dateRangeSelectionText = "Date";
      }
    });

    this.creatingPlan
      .pipe(
        pairwise(),
        withLatestFrom(this.creatingPlan_error),
        tap(([[previousVal, currentVal], error]) => {
          // Close the dialog if its status changed from 'true' to 'false' and having no error
          if (previousVal === true && currentVal === false && !error) this.close();
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();

    this.updatingPlan
      .pipe(
        pairwise(),
        withLatestFrom(this.updatingPlan_error),
        tap(([[previousVal, currentVal], error]) => {
          // Close the dialog if its status changed from 'true' to 'false' and having no error
          if (previousVal === true && currentVal === false && !error) this.close();
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();

    if (!this.budgetPlan) {
      this.latestFuturePlan.subscribe(plan => {
        this.selectedLatestPlan = plan
      });
    } else {
      this.latestBudgetPlan.subscribe(plan => {
        this.selectedLatestPlan = plan
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private bindFormControlToProp() {
    this.form_rangeSelected.valueChanges.subscribe(result => {
      this.onChangePresettedDateRanges(result[0])
    });
    this.form_rangeSelected.valueChanges
      .pipe(
        map(range => {
          if (this.dateRangeSelectionText === "Month") {
            this.workspaceService.resetRange(range);
          }
          return range;
        }),
        map(convertToSimpleDateRangeFromRangepickerVal),
        filter((data) => !!data.start && !!data.end),
        tap((data) => (this.planDateRange = data)),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  onChangePresettedDateRanges(e: Date) {
    this.presettedDateRanges = {
      'One year plan': [e, addYears(e, 1)],
      'Two year plan': [e, addYears(e, 2)],
      'Three year plan': [e, addYears(e, 3)],
      'Five year plan': [e, addYears(e, 5)],
    }
  }

  changeMode(checked: boolean) {
    this.updateMode = checked;
  }

  changeDate(e) {
    this.onChangePresettedDateRanges(e[0])
  }

  changeCreateMode(checked: boolean) {
    this.useForecasting = checked;

    if (checked) {
      this.selectedPlanImport = undefined;
    }
  }

  changePlanType(checked: boolean) {
    this.budgetPlan = checked;

    if (!this.budgetPlan) {
      this.latestFuturePlan.subscribe(plan => {
        this.selectedLatestPlan = plan
      });
    } else {
      this.latestBudgetPlan.subscribe(plan => {
        this.selectedLatestPlan = plan
      });
    }

    if (checked) {
      this.selectedPlanImport = undefined;
    }
  }

  changeUpdateMode(checked: boolean) {
    this.useForecasting = checked;

    if (checked) {
      this.selectedUpdatePlanImport = undefined;
    }
  }

  create() {
    this.store.dispatch(
      BEGIN_CREATE_NEW_PLAN({
        params: {
          planImport: this.selectedPlanImport,
          title: this.planTitle,
          description: this.planDescription,
          useForecasting: this.useForecasting,
          futureStartDate: this.planDateRange?.start || '',
          futureEndDate: this.planDateRange?.end || '',
          forecastingMethod: this.forecastingMethod,
          isBudgetPlan: this.budgetPlan,
        },
      }),
    );
  }

  update() {
    this.store.dispatch(
      BEGIN_UPDATE_PLAN({
        params: {
          planId: this.selectedLatestPlan?.id || '',
          useForecasting: this.useForecasting,
          forecastingMethod: this.forecastingMethod
        }
      })
    );
  }

  showMessage(title: string, content: string, durartion: number, status: string) {
    this.toastrService.show(content, title, {
      position: NbGlobalLogicalPosition.BOTTOM_START,
      duration: durartion,
      destroyByClick: true,
      status: status,
    });
  }

  showWarningDeleteDialog() {
    this.dialogService.open(DeletePlanWarningDialog, {
      closeOnBackdropClick: false,
      context: {
        latestPlanName: this.selectedLatestPlan?.name || '',
        onSubmit: (ref: NbDialogRef<DeletePlanWarningDialog>) => {
          this.store.dispatch(BEGIN_DELETE_PLAN({
            params: { plan: this.selectedLatestPlan }
          }));
          this.dialogRef.close();
          ref.close();
        }
      },
    });
  }

  close() {
    this.dialogRef.close();
  }

  generateCreateBtnClass() {
    return this.planTitle != '' && this.selectedPlanImport != null
      ? 'plan-mgt-btn -save'
      : 'plan-mgt-btn -cancel';
  }

  isCreateButnDisabled(): boolean {
    if (!this.useForecasting) {
      if (!this.selectedPlanImport) {
        return true;
      }
    } else {
      if (!this.planDateRange) {
        return true;
      }
    }

    if (this.planTitle === '') {
      return true;
    }

    return false;
  }

  isUpdateButnDisabled(): boolean {
    if (!this.useForecasting) {
      if (!this.selectedLatestPlan) {
        return true;
      }
    }
    return false;
  }

  openForecastSettingsDialog() {
    this.dialogService.open(ForecastSettingsComponent, {
      hasBackdrop: true,
      closeOnEsc: true,
      closeOnBackdropClick: false
    });
  }

  changeForecastingMethod(value: string) {
    this.forecastingMethod = value
  }
}
