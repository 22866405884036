import { groupBy } from 'rambda';
import { format, compareDesc } from 'date-fns';
import { Scenario } from './scenario';
import { Event } from './event';
import { ObjectRef } from '../common/mongo';
import { Workspace } from '../common/workspace';
import { TaskStatus } from '../../backend/simcel/task.service';

export enum PlanFlag {
  ACTUAL = 'actual',
  BUDGET = 'budget'
}

/**
 * Defines a S&OP plan.
 *
 * For more detailed information, checkout the doc at:
 * https://cel-coinnov.atlassian.net/wiki/spaces/SC/pages/554467367/Database+Modelling
 */
export declare interface Plan {
  id: string;
  name: string;

  currentPlanStartDate: string;
  currentPlanEndDate: string;
  futurePlanStartDate: string;
  futurePlanEndDate: string;
  defaultPlanDisplayStartDate: string;
  defaultPlanDisplayEndDate: string;

  masterInputDatabase: string;
  storageDatabase: string;

  previousPlanCycle?: ObjectRef<Plan>;
  primaryScenario?: ObjectRef<Scenario>;

  scenarios: ObjectRef<Scenario>[];
  events: ObjectRef<Event>[];
  workspace: Workspace;

  /** Supposed to be SegmentSelection, but it's going to be revised soon. */
  segments: ObjectRef<any>[];

  flags?: PlanFlag[];
  company: string;
  // A list of task ids of task that are related to this Plan
  tasks?: string[];

  // an obs storing a summarised status of all the related tasks
  tasksSummary?: {
    error?: string;
    status: TaskStatus;
  };

  forecastCollection: string;
}

interface GroupPlan {
  [key: string]: any;
};

export interface MenuTreeNode<T> {
  data: T;
  children?: MenuTreeNode<T>[];
  expanded?: boolean;
}

export interface PlanEntry {
  name: string;
  plan?: Plan;
}

/** Sorts the list of plan from latest plan start date to earliest, with "Actual" at the end. */
export function sortPlansByStartDate(plans: Array<Plan>): Plan[] {
  return (plans || []).sort((a, b) => {
    if (a?.flags?.includes(PlanFlag.ACTUAL)) {
      return 1;
    }
    if (b?.flags?.includes(PlanFlag.ACTUAL)) {
      return -1;
    }
    return b?.futurePlanStartDate?.localeCompare(a?.futurePlanStartDate);
  });
}

export function groupPlansByYear(plans: Array<Plan>): MenuTreeNode<PlanEntry>[] {
  // Get actual plan out of the array
  const actualPlan: Plan | undefined = plans.find(plan => plan.flags?.includes(PlanFlag.ACTUAL))
  // Get budget plans out of the array
  let bugdetPlans: Plan[] = plans.filter(plan => plan.flags?.includes(PlanFlag.BUDGET))
  bugdetPlans = sortPlansByFutureStartDateDesc(bugdetPlans)

  let futurePlans: Plan[] = plans.filter(plan => plan.flags?.length == 0)

  futurePlans = sortPlansByFutureStartDateDesc(futurePlans)

  const groupedPlansTreeNode: MenuTreeNode<PlanEntry>[] = [];

  // Push actual plan to top if we have one
  if (actualPlan) {
    const actualPlanMenuItem: MenuTreeNode<PlanEntry> = {
      data: { name: actualPlan.name, plan: actualPlan }
    }

    groupedPlansTreeNode.push(actualPlanMenuItem)
  }

  groupedPlansTreeNode.push(...groupPlans(bugdetPlans, "Budget Plans"))

  groupedPlansTreeNode.push(...groupPlans(futurePlans, "Tactical Plans"))

  return groupedPlansTreeNode;
}

const groupPlans = (plans: Plan[], planType: string): MenuTreeNode<PlanEntry>[] => {
  // Group plans by year
  const groupedPlans: GroupPlan = byYears(plans);
  const planYearsSortDesc: GroupPlan = Object.keys(groupedPlans).sort().reverse()
  const groupedPlansSortedDesc = new Map()
  planYearsSortDesc.forEach(year => {
    groupedPlansSortedDesc.set(year, groupedPlans[year])
  })

  // Go through grouped budget plans and convert it to menu format
  const groupedPlansTreeNodeByYear: MenuTreeNode<PlanEntry>[] = []
  for (const [key, value] of groupedPlansSortedDesc) {
    groupedPlansTreeNodeByYear.push({
      data: { name: key },
      children: value.map(i => ({
        data: { name: i.name, plan: i }
      }))
    })
  }

  return [{
    data: { name: planType },
    children: groupedPlansTreeNodeByYear
  }]
}

const sortPlansByFutureStartDateDesc = (plans: Array<Plan>): Array<Plan> => {
  return plans.sort((a, b) => {
    const aFuturePlanStartDateArr = a.futurePlanStartDate.split('-')
    const bFuturePlanStartDateArr = b.futurePlanStartDate.split('-')

    const [aYear, aMonth, aDay] = aFuturePlanStartDateArr
    const [bYear, bMonth, bDay] = bFuturePlanStartDateArr

    const iAYear = Number(aYear)
    const iAMonth = Number(aMonth)
    const iADay = Number(aDay)

    const iBYear = Number(bYear)
    const iBMonth = Number(bMonth)
    const iBDay = Number(bDay)

    return compareDesc(new Date(iAYear, iAMonth, iADay), new Date(iBYear, iBMonth, iBDay))
  });
}

const byYears = groupBy((plan: Plan) => {
  const futurePlanStartDateArr = plan.futurePlanStartDate.split('-')
  const [year, month, day] = futurePlanStartDateArr

  const iYear = Number(year)
  // month param in Date of javascript start from 0 to 11
  const iMonth = Number(month) - 1
  const iDay = Number(day)

  return format(new Date(iYear, iMonth, iDay), 'yyyy')
});
