<nb-card>
  <nb-card-header>Warning</nb-card-header>
  <nb-card-body>
    Deleting "<b>{{this.latestPlanName}}</b>" will delete all the data linked to it:<br />
    - The Forecast Base <br />
    - All Scenarios linked to this Plan <br />
    - The Primary Scenario linked to this Plan <br /><br />
    This operation cannot be reverted, are you sure want to proceed?
  </nb-card-body>
  <nb-card-footer>
    <button nbButton (click)="dismiss()">Cancel</button>
    <button nbButton (click)="submit()" status="primary">Proceed</button>
  </nb-card-footer>
</nb-card>