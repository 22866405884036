import { Component, Input } from '@angular/core';
import { DimensionGroup, DIMENSION_GROUPS } from '@/app/@core/interfaces/business/breakdown';
import { NbDialogRef } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { ECharts, EChartsOption } from 'echarts';
import { select_segmentCustomersOptions, select_segmentProductsOptions } from '@/store/segment/segment.selectors';
import { select_params_loadForecastSettingsChartData, select_selectedWorkspace } from '@/store/workspace/workspace.selectors';
import { ForecastSettings, Workspace } from '@/app/@core/interfaces/common/workspace';
import { BEGIN_UPDATE_WORKSPACE_SETTINGS } from '@/store/workspace/workspace.actions';
import { DEFAULT_ECHARTS_OPTIONS, DEFAULT_ECHARTS_SERIES_OPTIONS, formatByDateAggregationGen, takeRowDataById } from '@/store/pages/demand-planning/demand-planning.utils';
import { DemandService } from '@/app/@core/entity/demand.service';
import { transformInputToKpiFormat } from '@/app/pipes/kpi-formatting.pipe';
import { DateAggregationOption } from '../timeseries/timeseries.constants';
import { sumIfHaveValue } from '@/utils/numbers';

@Component({
  selector: 'cel-forecast-settings',
  templateUrl: './forecast-settings.component.html',
  styleUrls: ['./forecast-settings.component.scss'],
})
export class ForecastSettingsComponent {
  echartsInstance?: ECharts;
  chartOption: EChartsOption = {
    grid: {
      width: 295, height: 150, top: 10, left: 0
    },
    xAxis: {
      show: false,
      type: 'category',
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        show: false
      }
    },
  };
  DEFAULT_ECHARTS_OPTIONS = DEFAULT_ECHARTS_OPTIONS;
  productChartOptions: EChartsOption = {};
  customerChartOptions: EChartsOption = {};

  segmentProductsOptions = this.store.select(select_segmentProductsOptions);
  segmentCustomersOptions = this.store.select(select_segmentCustomersOptions);
  @Input() selectedHorizon: string = '6 Months';
  @Input() selectedtimeAggr: string = 'Month';
  
  constructor(
    private readonly dialogRef: NbDialogRef<string[]>,
    private readonly store: Store,
    private readonly demandService: DemandService,
  ) { }

  horizons: string[] = ['6 Months', '1 year', '18 Months', '2 years', '3 years', '5 years'];
  timeAggrs: string[] = ['Month', 'Week', 'Day'];
  productGroups: Array<DimensionGroup> = DIMENSION_GROUPS.filter(dim => dim.name === "Product");
  customerLocationGroups: Array<DimensionGroup> = DIMENSION_GROUPS.filter(dim => dim.name !== "Product");
  selectedProductAggr: string[] = ["ProductID"];
  selectedCustomerAggr: string[] = ["CustomerRef"];
  productsOptions: string[] = [];
  customersOptions: string[] = [];
  selectedProductsOption: string = "";
  selectedCustomersOption: string = "";
  delistedProductRef: string[] = [];
  delistedCustomerRef: string[] = [];
  forecastConfig: ForecastSettings | undefined = undefined;
  workspace: Workspace | undefined = undefined;
  params: any = undefined;
  productChartDataLoading = false;
  customerChartDataLoading = false;

  ngOnInit(): void {
    this.segmentProductsOptions.subscribe(options => {
      this.productsOptions = options.map(option => option.productId as string);
    });
    
    this.segmentCustomersOptions.subscribe(options => {
      this.customersOptions = options.map(option => option.ref as string);
    });

    this.store.select(select_selectedWorkspace).subscribe((workspace: Workspace) => {
      if (workspace != undefined && workspace.hasOwnProperty("settings")) {
        this.forecastConfig = workspace.settings?.forecastConfig;
      }
      
      if (this.forecastConfig != undefined) {
       this.selectedHorizon = this.forecastConfig.ForecastHorizon;
       this.selectedProductAggr = this.forecastConfig.ProductAggregation;
       this.selectedCustomerAggr = this.forecastConfig.CustomerAggregation;
       this.selectedtimeAggr = this.forecastConfig.TimeAggregation;
       this.delistedProductRef = this.forecastConfig.ProductDelisting;
       this.delistedCustomerRef = this.forecastConfig.CustomerDelisting;
      }

      this.workspace = workspace;
    });

    this.store.select(select_params_loadForecastSettingsChartData).subscribe((params) => {
      this.params = params;
    })
  }

  onChartInit(ec) {
    this.echartsInstance = ec;
  }

  save() {
    const forecastConfig = {
      ForecastHorizon: this.selectedHorizon,
      ProductAggregation: this.selectedProductAggr,
      CustomerAggregation: this.selectedCustomerAggr,
      TimeAggregation:  this.selectedtimeAggr,
      ProductDelisting: this.delistedProductRef,
      CustomerDelisting: this.delistedCustomerRef,
    }
    this.store.dispatch(
      BEGIN_UPDATE_WORKSPACE_SETTINGS({
        params: {
          id: this.workspace?.id as string,
          settings: JSON.stringify(
            {
              ...this.workspace?.settings,
              forecastConfig
            }
          )
        }
      })
    );
    this.close();
  }

  close() {
    this.dialogRef.close();
  }

  changeHorizon(horizon: string): void {
    this.selectedHorizon = horizon;
  }

  updateCharts() {
    this.getEchartsOption(this.selectedProductsOption, "product");
    this.getEchartsOption(this.selectedCustomersOption, "customer");
  }

  changeTimeAggr(timeAggr: string): void {
    this.selectedtimeAggr = timeAggr;
    this.updateCharts();
  }

  onRadioClick(horizon: string): void {
    this.selectedHorizon = horizon;
  }

  changeSelectedProductAggr(value: string[]): void {
    this.selectedProductAggr = value;
    this.updateCharts();
  }

  changeSelectedCustomerAggr(value: string[]): void {
    this.selectedCustomerAggr = value;
    this.updateCharts();
  }

  getEchartsOption(value: string, type: string) {
    if (type === "product") {
      this.productChartDataLoading = true;
    } else if (type === "customer") {
      this.customerChartDataLoading = true;
    }
    const newParams = {
      ...this.params,
      interval: this.selectedtimeAggr.split("")[0],
      segment: {
        customer: type === "customer" ? [
          {
            shipTo: [value],
          }
        ] : [],
        dc: [],
        formatVersion: '1',
        location: [],
        name: "",
        product: type === "product" ? [
          {
            productId: [value],
          }
        ] : [],
        type: "filter",
      },
      groupingColumns: this.selectedCustomerAggr.map(customer => `Customer.${customer}`).concat(this.selectedProductAggr.map(product => `Product.${product}`))
    }
    
    this.demandService.getDemands(newParams).subscribe((data => {
      const scenarios = [{
        id: "actual",
        name: "Actual",
        color: "#979797"
      }];
      const rows: any = scenarios.map((s) => {
        const demands =
          takeRowDataById(s.id, data.rows) ||
          takeRowDataById('forecast', data.rows) ||
          [];
        return [s.id, demands.map((val, i) => sumIfHaveValue(
          val ? val : 0,
          0))];
      });

      const combinedChartData = {
        columns: data.columns,
        rows
      }

      const echartsOption = <EChartsOption>{
        xAxis: {
          data: combinedChartData.columns,
          axisLabel: { formatter: formatByDateAggregationGen(DateAggregationOption.MONTH) },
        },
        yAxis: {
          axisLabel: { formatter: transformInputToKpiFormat },
          splitLine: { show: true },
        },
        color: scenarios.map((s) => s.color || '#0C80EB'),
        series: scenarios.map(({ id, name }) => ({
          id,
          name,
          emphasis: { disabled: true },
          data: takeRowDataById(id, combinedChartData.rows),
          ...DEFAULT_ECHARTS_SERIES_OPTIONS,
          lineStyle: { width: 1 },
          showSymbol: combinedChartData.columns.length > 1 ? false : true,
          type: 'line'
        })),
      };

      if (type === "product") {
        this.productChartOptions = echartsOption;
        this.productChartDataLoading = false;
      } else if (type === "customer") {
        this.customerChartOptions = echartsOption;
        this.customerChartDataLoading = false;
      }
    }));
  }

  changeProductsOption(value: string): void {
    this.selectedProductsOption = value;
    this.getEchartsOption(value, "product");
  }

  changeCustomersOption(value: string): void {
    this.selectedCustomersOption = value;
    this.getEchartsOption(value, "customer");
  }

  addDelistedProductRef(): void {
    if (this.selectedProductsOption !== "" && !this.delistedProductRef.includes(this.selectedProductsOption)) {
      this.delistedProductRef = [...this.delistedProductRef, this.selectedProductsOption];
    }
  }

  addDelistedCustomerRef(): void {
    if (this.selectedCustomersOption !== "" && !this.delistedCustomerRef.includes(this.selectedCustomersOption)) {
      this.delistedCustomerRef = [...this.delistedCustomerRef, this.selectedCustomersOption];
    }
  }
}
